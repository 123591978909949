<!-- 已报读课程的列表，与ClassNotify类似，使用cell列表，多一个下划线选择更多 -->
<template>
  <div class="classnotify">
  <nut-cell-group v-for="(item, index) in classselectList" :key="index" @click="tomore"> 
    <nut-cell title="课程名称" :desc="item.name">
      <template #icon>
      <Star />
     </template>
    </nut-cell>
    <!-- 学员 -->
    <nut-cell title="学员" :desc="item.student">
      <template #icon>
      <My />
     </template>
    </nut-cell>
    <!-- 所在班级 -->
    <nut-cell title="所在班级" :desc="item.classname">
      <template #icon>
      <Category />
     </template>
    </nut-cell>
    <!-- 剩余课时 -->
    <nut-cell title="剩余课时" :desc="item.left">
      <template #icon>
      <Clock />
     </template>
    </nut-cell>
    <!-- 分割线 -->
    <nut-divider :style="{margin: '0 '}" />
    <!-- 更多 -->
    <nut-cell title="查看更多" style="color:red"></nut-cell>
  </nut-cell-group>
</div>
</template>

<script>
import { My,Star,Clock,Category } from '@nutui/icons-vue-taro';
export default {
  components: {
    My,Star,Clock,Category
  },
  data() {
    return {
      classselectList: [
        {
          name: '钢琴课',
          student: '张三',
          classname: '高年级C++班',
          left: '10课时'
        }
      ]
    }
  },
  methods: {
    tomore() {
      console.log('查看更多');
    }
  }
};
</script>

<style>
.classnotify {
  background-image: linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%);
  height: 100%;
  background-attachment:fixed;
}
</style>